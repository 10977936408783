






import React, { useEffect } from "react";
import lax from "lax.js";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import OwlCarousel from "react-owl-carousel3";
import logo_education from "../../assets/images/logo_education.png";
import logo_universite_sousse from"../../assets/images/logo_unvsousse.jpg";
import logo_iwatch from"../../assets/images/logo_iwatch.jpg";
import logo_GIZ from"../../assets/images/logo_giz.jpg";

import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const Partner = (props) => {
    const options = {
        dots: false,
        autoplay: true,
        smartSpeed: 100,
        loop: true,
        nav: false,
        margin: 8,
        startPosition: 1,
        animateOut: true,
        animateIn: true,
        navText: [
            "<i class='icofont-circled-left '></i>",
            "<i class='icofont-circled-right'></i>",
        ],
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            576: {
                items: 4,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 4,
            },
        },
    };

 

    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two mb-5">
            <div className="container">
           
            <div className="section-title about-section-title">
    <h2>{strings.nospartenaires}</h2>
    <div className="bar-line">
        <span className="bar"></span>
    </div>
</div>
<OwlCarousel
    {...options}
    className="platinum-partner-slides owl-carousel owl-theme carsoul"
    style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px' }}
>
    <div className="nospartner" style={{ padding: '10px', textAlign: 'center' }}>
        <div className="partner-image">
            <img src={logo_education} alt="logo" height={80} style={{ transition: 'transform 0.3s ease' }} />
        </div>
    </div>

    <div className="nospartner" style={{ padding: '10px', textAlign: 'center' }}>
        <div className="partner-image">
            <img src={logo_universite_sousse} alt="logo" height={80} style={{ transition: 'transform 0.3s ease' }} />
        </div>
    </div>
    <div className="nospartner" style={{ padding: '10px', textAlign: 'center' }}>
        <div className="partner-image">
            <img src={logo_iwatch} alt="logo" height={80} style={{ transition: 'transform 0.3s ease' }} />
        </div>
    </div>
    <div className="nospartner" style={{ padding: '10px', textAlign: 'center' }}>
        <div className="partner-image">
            <img src={logo_GIZ} alt="logo" height={80} style={{ transition: 'transform 0.3s ease' }} />
        </div>
    </div>
</OwlCarousel>

            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Partner);

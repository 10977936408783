import React from "react";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import axios from "../../api/axios";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";

import { connect } from "react-redux";
import Modal from "./Modal";
const strings = new LocalizedStrings(localeFile);
class EventSchedulesOne extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            string: new LocalizedStrings(localeFile),
            showMore: false,
            event: [],
            nextevent: [],
            j: 0,
            image: "",
            dynamicData: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the , when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
            title: "",
            myArray: [],
            date: new Date(),
        };
    }
   
    
      toggleShowMore = () => {
        this.setState((prevState) => ({
          showMore: !prevState.showMore,
        }));
      };
    handleClick = (day) => {
      
    };
    componentDidMount() {
        axios.get(`getEvent`).then((res) => {
            const event = res.data.event;
            this.setState({ myArray: event });

            this.setState({
                image:
                    "https://dashboard.futurevisions.tn/" +
                    event[event.length - 1].image,
            });
            this.setState({ nextevent: event[event.length - 1] });
            this.setState({ title: event[event.length - 1].title });
            this.setState({
                date:
                    new Date(event[event.length - 1].date).getDate() +
                    "/" +
                    new Date(event[event.length - 1].date).getMonth() +
                    "/" +
                    new Date(event[event.length - 1].date).getFullYear(),
            });

            renderCalender();
        });
        let date = new Date();
      
    
        const currerntDate = document.querySelector(".current-date");
        var daysTag = document.querySelector(".days");
        let prevNextIcon = document.querySelectorAll(".icons span");
     
        let currYear = date.getFullYear();
        let currMonth = date.getMonth();
        const  months = this.state.string.hreflangCode == "fr" ?  [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre ",
            "Octobre",
            "Novembre ",
            "Décembre",
        ]:["جانفي" ,"فيفري " ,"مارس ", "أفريل ", "ماي ", "جوان", "جويلية " , "أوت ","سبتمبر  " ,"أكتوبر ","نوفمبر  ","ديسمبر "];

        const monthsEng = [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre ",
            "Octobre",
            "Novembre ",
            "Décembre",
        ];
        const renderCalender = () => {
            let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(),
                lastDateofMonth = new Date(
                    currYear,
                    currMonth + 1,
                    0
                ).getDate(),
                lastDayeofMonth = new Date(
                    currYear,
                    currMonth,
                    lastDateofMonth
                ).getDay(),
                lastDateofLastMonth = new Date(
                    currYear,
                    currMonth,
                    0
                ).getDate();

            let liTag = "";

            for (let i = firstDayofMonth; i > 0; i--) {
                liTag += ` <li class="inactive">${
                    lastDateofLastMonth - i + 1
                }</li>`;
            }
            let lengtharay = this.state.myArray.length;

            for (let i = 2; i <= lastDateofMonth; i++) {
                let isToday =
                    i === date.getDate() &&
                    currMonth === new Date().getMonth() &&
                    currYear === new Date().getFullYear()
                        ? "active"
                        : "";

                let dayEvent = "";
                let isEventDay = "";

                for (let j = 0; j < this.state.myArray.length; j++) {
                    if (
                        i === new Date(this.state.myArray[j].date).getDate() &&
                        currMonth ===
                            new Date(this.state.myArray[j].date).getMonth() &&
                        currYear ===
                            new Date(this.state.myArray[j].date).getFullYear()
                    ) {
                        dayEvent = this.state.myArray[j].title;
                        isEventDay = "isEvent";
                    }
                }

                liTag += `
                        <li class="${
                            isToday === "" ? isEventDay : isToday
                        }" )()">
                          ${i}
                          <div class="${
                              dayEvent !== "" ? "hover-container" : ""
                          }">
                            ${dayEvent}
                          </div>
                        </li>
                      `;
            }

            for (let i = lastDayeofMonth; i < 6; i++) {
                liTag += ` <li class="inactive">${
                    i - lastDayeofMonth + 1
                }</li>`;
            }
            currerntDate.innerText = `${  months[currMonth]} ${currYear}`;
            daysTag.innerHTML = liTag;
            const d = document.querySelectorAll(".days li");

            d.forEach((icon) => {
                icon.addEventListener("click", () => {
                    const numberRegex = /\d+/; // Matches one or more digits

                    for (let j = 0; j < this.state.myArray.length; j++) {
                        if (
                            icon.textContent.match(numberRegex)[0] ==
                                new Date(
                                    this.state.myArray[j].date
                                ).getDate() &&
                            currMonth ===
                                new Date(
                                    this.state.myArray[j].date
                                ).getMonth() &&
                            currYear ===
                                new Date(
                                    this.state.myArray[j].date
                                ).getFullYear()
                        ) {
                            this.setState({
                                title: this.state.myArray[j].title,
                            });
                            this.setState({
                                date:
                                    new Date(
                                        this.state.myArray[j].date
                                    ).getDate() +
                                    "/" +
                                    new Date(
                                        this.state.myArray[j].date
                                    ).getMonth() +
                                    "/" +
                                    new Date(
                                        this.state.myArray[j].date
                                    ).getFullYear(),
                            });
                            this.setState({
                                image:
                                    "https://dashboard.futurevisions.tn/" +
                                    this.state.myArray[j].image,
                            });
                        } else {
                        }
                    }
                });
            });
        };

        prevNextIcon.forEach((icon) => {
            icon.addEventListener("click", () => {
                currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

                if (currMonth < 0 || currMonth > 11) {
                    date = new Date(currYear, currMonth);
                    currYear = date.getFullYear();
                    currMonth = date.getMonth();
                } else {
                    date = new Date();
                }
                renderCalender();
            });
        });
    }

    render() {
        strings.setLanguage(this.props.languageCode);

        return (
            <section className="schedule-area-calender bg-image">
                <div className="container">
                    <div className="section-title">
                        <h2 className="mb-5">
                            {" "}
                            <b> {strings.event}</b>
                        </h2>

                        <div className="bar"></div>
                    </div>
                    <div className=" calendrier">
                        <div className="wrapper">
                            <header>
                                <p className=" current-date"> </p>
                                <div className="icons">
                                    <span
                                        id="prev"
                                        className="material-symbols-rounded mx-1"
                                    >
                                        {strings.hreflangCode != "ar" ? (
                                            <IoIosArrowDropleft />
                                        ) : (
                                            <IoIosArrowDropright />
                                        )}
                                    </span>
                                    <span
                                        id="next"
                                        className="material-symbols-rounded mx-1"
                                    >
                                        {strings.hreflangCode == "fr" ? (
                                            <IoIosArrowDropright />
                                        ) : (
                                            <IoIosArrowDropleft />
                                        )}
                                    </span>
                                </div>
                            </header>
                            <div className="calendar">
                                <ul className="weeks">
                                    <li>Lu</li>
                                    <li>Ma</li>
                                    <li>Me</li>
                                    <li>Je</li>
                                    <li>Ve</li>
                                    <li>Sa</li>
                                    <li>Di</li>
                                </ul>

                                <ul className="days"></ul>
                            </div>
                        </div>

                        <div className="next-events scroll-bar">
                        <div className="next-events-title">
                        <h4 className="mb-1">
                            {" "}
                            <b> {strings.nextevents}</b>
                        </h4>

                        <div className="barevents"></div>
                    </div>
               

                            {this.state.myArray.map((event, index) => (
                                 new Date(
                                    event.date
                                )  >  new Date()?  <div>
                                     <div className="next-event-item-date">
                                                  <br/><span>{ new Date(
                                    event.date
                                ).getDate()}/{new Date(
                                    event.date
                                ).getMonth()}/{new Date(
                                    event.date
                                ).getFullYear()}</span>
                                                 </div>
                                         <div className="next-event-item">
             
                                             <div className="next-event-item-image"> 
                                                 <img src={"https://dashboard.futurevisions.tn/"+event.image} alt="" />
                                             </div>
                                             <div className="next-event-item-info">
                                                 <h5>
                                                     {event.title}
                                                 </h5>
                                               
             
                                                 <div className="next-event-item-description">
                                                     <p>  {event.descrition}
                                                        </p>
                                                 </div>
                                             </div>
                                         </div></div>:''
                                    ))}

                         

                        </div>

                        {/* <div className="event">
                            <div className="image-event">
                                <img src={this.state.image} alt="" />
                                <div className="event-title">
                                    <h3>
                                        {this.state.title}Lorem Ipsum is simply
                                        dummy text{" "}
                                    </h3>
                                    <div className="event-date">
                                        <span>{this.state.date}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="event-description">
        <p>
          {this.state.showMore ? this.state.dynamicData :
           <p>
         { this.state.dynamicData.slice(0, 200)+'...' }<span> <Modal title={this.state.title} description={this.state.dynamicData} date={this.state.date}image={this.state.image} /></span> </p>}
        
        </p>
      </div>
                            
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(EventSchedulesOne);

import React from 'react'

function TeamCard({image,name,poste,linkedin,email}) {
  return (
    <div className='card'>
    <div className='content'>
        <div className='imgBx'>
        <img src={image} alt='profile'/>
        </div>
        <div className='contentBx'>
        <h3>{name}</h3>
        <h5>{poste}</h5>
        </div>
    </div>
    <ul className='sci'>
    <li >
    <a href={linkedin} target="_blank" rel="noopener noreferrer"><i className="icofont-linkedin  custom-icon "></i></a>

    </li>
    <li >
    <a  href={`mailto:${email}`}><i class="icofont-ui-email custom-icon "></i></a>

    </li>
    </ul>
</div>
  )
}

export default TeamCard

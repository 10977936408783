const content = {
  fr:{
    hreflangCode: "fr",
    Team:"Notre équipe",
    baseUrl: "https://www.futurevisions.tn/",
    En_savoir_plus:"En savoir plus",
    Organisations_Members:"Initiatives et projets    ",
    Voir_tous_les_membres:'Voir tous',
    ACTUALITÉS:"Actualités ",
    Nos_dernières_nouvelles:'Nos dernières nouvelles',
    Voir_toutes_les_nouvelles:'Voir tous',
    Accueil:'Acceuil',
    a_propos:'À propos',
    ORGANISATIONS :'Initiatives et projets ',    
    ORGANISATIONSdescrip :'ORGANISATIONS ',
    RESSOURCES:'Plus',
    a_PROPOS_DE_NOUS:'À propos de nous',
    Galerie:'Galerie',
    Galerie_Des_Photos:'Galerie Des Photos',
    Galerie_Des_Videos:'Galerie Des Videos',
    GUIDE:'Rapports annuels',
    podcast:'podcast',
    video:'Video',
    regarderz_video_promo:'Regarder video promo',
    Télécharger:'Télécharger',
    Loading:'Chargement ...',
    Top:'Top',
    Contact :'Contact ',
    CONTACTUS:'Contactez-nous',

    OBJECTIFS:'Objectifs',
    CIBLE :'Cible ',
    DOMAINE:'Domaine',
    Catégories:'Catégories',
    a_LIRE_AUSSI:'À lire aussi',
    PARTAGER_SUR:'PARTAGER SUR',
    All_rights_reserved:'Tous droits réservés',
    envoyermessage:'Votre message a été envoyé',
    merci:'Merci',
    envoyer:'Envoyer',
    Name:'Nom',
    Email:'Email',
    Phone:'Numéro de téléphone ',
    Message:'Message',
    FutureVisions:'Future Visions',
    Round:'ROUND ',
    nospartenaires:'Nos partenaires',
    months:["Janvier" ,"Février" ,"Mars", "Avril", "Mai", "Juin", "Juillet" , "Août","Septembre " ,"Octobre","Novembre ","Décembre"]
    ,
    jours:[   "Lu",
    "Ma",
     "Me",
    "Je",
  "Ve",
   "Sa",
    "Di"],
    event:'Les événements',
    NVision:'Notre Vision',
    NMission:'Notre Mission',
    NSlogan:'Notre Slogan',
    vision:'Un environnement jeune inspirant, innovant et socialement impactant',
    mission:"Renforcer et inspirer les jeunes en leur fournissant les compétences, connaissances et opportunités nécessaires, les dotant ainsi des moyens de participer activement aux processus décisionnels et de promouvoir des solutions efficaces et innovantes.",
    slogan:'Innover avec la nouvelle génération pour la nouvelle génération',
    values:'Nos Valeurs',
    strategy:'Notre stratégie',
    Excellence:'Excellence',
    ExcellenceT:'Nous aspirons à être les meilleurs dans nos initiatives.',
    Inclusivity:'Inclusivité', 
    InclusivityT:"Nous nous engageons à être inclusifs dans notre travail afin d'atteindre tous les jeunes",
    Innovation:'Innovation',
    InnovationT:"Favoriser une culture de l'innovation et de la pensée créative pour relever les défis.",
    Integrity:'Intégrité',
    IntegrityT:"Maintenir l'honnêteté, la transparence et un comportement éthique dans toutes les interactions et activités.",
    SocialImpact:'Impact social ',
    SocialImpactT:'Engagement à faire une différence positive et significative dans la société.',
    ByYouth:'Par les jeunes,pour les jeunes ',
    ByYouthT:" De la conception à la réalisation, nous sommes fiers d'être une organisation guidée par la jeunesse.",
    youthBen : "Jeunes hommes et femmes",
    partnersBen : "Partenaires",
    projetsBen : "Projets",
    nextevents:'Prochains événements',
    president: 'Président',
    seif:'SeifAllah Ben Salem',
    yassine:'Mohamed Yassine Ben Abdelkrim',
    treasurer:'Trésorier',
    lamiss:'Lamiss Khalfallah',
    secretary:'Secrétaire général ',
    latifa:'Latifa Bargou',
    financeRes:'Responsable financière',
    amel:'Amel Lagha',
    coord:'Coordinatrice de projet',
    rania:'Rania Lakoud',
    webdev:'Web developer',
    phone:'Téléphone / Fax',
    address:'Adresse',
    list:'Liste des fonds',
    valeurDinar : "Valeur de la donation en dinars",
    funder : "Financeur",
    year : "Année",
    topic : "Sujet de la donation",
    email:'E-mail',

},
    ar: 
    {
        Team:"فريقنا",
    hreflangCode: "ar",
    baseUrl: "https://www.futurevisions.tn/",
    En_savoir_plus:" اقرأ المزيد",
    Organisations_Members :'مبادرات ومشاريع',
    Voir_tous_les_membres:'عرض الكل ',
    ACTUALITÉS:"المستجدات ",
    Nos_dernières_nouvelles:'آخر أخبارنا',
    Voir_toutes_les_nouvelles:'عرض كل المستجدات',
    Accueil:'الرئيسية ',
    a_propos:'  حولنا',
    ORGANISATIONS :'مبادرات ومشاريع',
    RESSOURCES:'المزيد',
    a_PROPOS_DE_NOUS:'معلومات عنا',
    Galerie:'المعرض',
    Galerie_Des_Photos:'معرض الصور',
    Galerie_Des_Videos:'معرض الفديوهات',
    GUIDE:'التقارير السنوية',
    podcast:'بودكاست',
    video:'الفديوهات',
    regarderz_video_promo:' مشاهدة الفيديو الترويجي',
    Télécharger:'تحميل',
    Loading:'... جار التحميل',
    Top:'صعود',
    Contact :'للتواصل',
    OBJECTIFS:'اهدافها',
    CIBLE :'تستهدف',
    DOMAINE:'الإختصاص',
    Catégories:'الفئات',
    a_LIRE_AUSSI:'اقرأ أيضا',
    PARTAGER_SUR:'شارك على',
    All_rights_reserved:'جميع الحقوق محفوظة',
    CONTACTUS:'تواصل معنا',
    envoyermessage:'تم إرسال الرسالة بنجاح',
    merci:'شكرا',
    envoyer:'إرسال',
    Name:': الإسم',
    Email:': البريد الإلكتروني ',
    Phone:': رقم الهاتف ',
    Message:': الرسالة ',
    FutureVisions:'رؤى المستقبل',
    Round:'جولة الرؤى',
    ORGANISATIONSdescrip:'الجمعيات ',
    nospartenaires:'الشركاء',
    jours:[   "الأحد",
    "الاثنين",
     "الثلاثاء",
    "الأربعاء",
  "الخميس",
   "الجمعة",
    "السبت"],

    months:["جانفي" ,"فيفري " ,"مارس ", "أفريل ", "ماي ", "جوان", "جويلية " , "أوت ","سبتمبر  " ,"أكتوبر ","نوفمبر  ","ديسمبر "],
    event:'الأحداث',
    NVision:'رؤيتنا',
    NMission:'مهمتنا',
    NSlogan:'شعارنا',
    vision:'بيئة شبابية ملهمة ومبتكرة ذات تأثير مجتمعي',
    mission:'تمكين الشباب من خلال توفير المهارات والمعارف والفرص التي تمكنهم من المشاركة الإيجابية في صنع القرار وإيجاد حلول مبتكرة وفعالة.',
    slogan:'الابتكار مع الجيل القادم من أجل الجيل القادم',
    values:'قيمنا',
    strategy:'استراتيجيتنا',
    Excellence:'التميز',
    ExcellenceT:'نطمح أن نكون الأفضل في مبادراتنا',
    Inclusivity:'الشمولية', 
    InclusivityT:'نسعى لأن نكون شاملين في عملنا لنصل إلى جميع الشباب',
    Innovation:'الابتكار',
    InnovationT:' تعزيز ثقافة الابتكار والتفكير الإبداعي استجابة للتحديات',
    Integrity:'النزاهة',
    IntegrityT:'الحفاظ على الصدق والشفافية والسلوك الأخلاقي في كافة التعاملات والأنشطة',
    SocialImpact:'التأثير الاجتماعي ',
    SocialImpactT:'الالتزام بإحداث تغيير إيجابي وهادف في المجتمع',
    ByYouth:'بواسطة الشباب، من أجل الشباب ',
    ByYouthT:'من التصميم إلى التنفيذ، نفخر بأننا منظمة تعمل من أجل الشباب ويقودها الشباب ',
    youthBen: "شاب وشابة",
    partnersBen:"شركاء",
    projetsBen:"مشاريع",
    nextevents:'الفعاليات القادمة',
    president: 'رئيس',
    seif:'سيف الله بن سالم',
    yassine:'محمد ياسين بن عبد الكريم',
    treasurer:'أمين مال',
    lamiss:'لميس خلف الله',
    secretary:'كاتب عام',
    latifa:'لطيفة برقو',
    financeRes:'مسؤولة مالية',
    amel:'آمال لاغة',
    coord:'منسقة مشاريع',
    rania:'رانية لكود',
    webdev:'مبرمجة',

    phone:'الهاتف / الفاكس',

    address:'العنوان',
    list:'قائمة التمويلات',
    valeurDinar:'قيمة الهبة بالدينار',
    funder:'الممول ',
    year:'السنة',
    topic:'موضوع الهبة',
    email:'البريد الالكتروني',

},

  en:{
    Team:"Our team",
    hreflangCode: "en",
    baseUrl: "https://www.futurevisions.tn/",
    En_savoir_plus: "Read more",
    Organisations_Members: "Initiatives and Projects",
    Voir_tous_les_membres: "View all",
    ACTUALITÉS: "News",
    Nos_dernières_nouvelles: "Our latest news",
    Voir_toutes_les_nouvelles: "View all",
    Accueil: "Home",
    a_propos: "About",
    ORGANISATIONS: "Initiatives and Projects",
    ORGANISATIONSdescrip: "ORGANIZATIONS",
    RESSOURCES: "More",
    a_PROPOS_DE_NOUS: "About us",
    Galerie: "Gallery",
    Galerie_Des_Photos: "Photo Gallery",
    Galerie_Des_Videos: "Video Gallery",
    GUIDE: "Annual Reports",
    podcast: "Podcast",
    video: "Video",
    regarderz_video_promo: "Watch promo video",
    Télécharger: "Download",
    Loading: "Loading...",
    Top: "Top",
    Contact: "Contact",
    CONTACTUS: "Contact Us",
    OBJECTIFS: "Objectives",
    CIBLE: "Target",
    DOMAINE: "Domain",
    Catégories: "Categories",
    a_LIRE_AUSSI: "Read also",
    PARTAGER_SUR: "Share on",
    All_rights_reserved: "All rights reserved",
    envoyermessage: "Your message has been sent",
    merci: "Thank you",
    envoyer: "Send",
    Name: "Name",
    Email: "Email",
    Phone: "Phone Number",
    Message: "Message",
    FutureVisions: "Future Visions",
    Round: "ROUND",
    nospartenaires: "Our Partners",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    event: "Events",
    NVision:'Our Vision',
    NMission:'Our Mission',
    NSlogan:'Our Slogan',
    vision:'An inspiring, innovative, and socially impactful youth environment',
    mission:'To empower and uplift young individuals by equipping them with the necessary skills, knowledge, and opportunities, therefore enabling them to actively participate in decision-making processes and drive effective and innovative solutions.',
    slogan:'Innovating with the next generation for the next generation',
    values:'Our Values',
    strategy:'Our Strategy',
    Excellence:'Excellence',
    Inclusivity:'Inclusivity',
    Innovation:'Innovation',
    Integrity:'Integrity',
    SocialImpact:'Social Impact',
    ByYouth:'By Youth, for Youth',
    ExcellenceT:'We strive to be the best at what we do.',
    InclusivityT:'We strive to be inclusive in our work to reach all youth.',
    InnovationT:'Foster a culture of innovation and creative thinking in response to challenges.',
    IntegrityT:'Maintaining honesty, transparency and ethical behavior in all interactions and activities.',
    SocialImpactT:'Commitment to making a positive and meaningful difference in society.',
    ByYouthT:'From conception to implementation, we are proud to be an organization that works for and is led by youth. ',
    youthBen: "Young Men and Women",
    partnersBen: "Partners",
    projetsBen: "Projects",
    nextevents:'Next events',
    president: 'President',
    seif:'SeifAllah Ben Salem',
    yassine:'Mohamed Yassine Ben Abdelkrim',
    treasurer:'Treasurer',
    lamiss:'Lamiss Khalfallah',
    secretary:'Secretary General',
    latifa:'Latifa Bargou',
    financeRes:'Financial Officer',
    amel:'Amel Lagha',
    coord:'Project Coordinator',
    rania:'Rania Lakoud',
    webdev:'Web developer',

    phone:'Phone / Fax',
    address:'Address',
    list:'List of Funds',
    valeurDinar : "Value of the donation in dinars",
    funder : "Funder",
    year : "Year",
    topic : "Topic of the donation",
    email:'E-mail',

},

}

export default content;

import React from "react";

import LocalizedStrings from "react-localization";
import localeFile from "../../../local/content";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
const strings = new LocalizedStrings(localeFile);

const Features = (props) => {
    strings.setLanguage(props.languageCode);
    const [count, setCount] = useState(900);
    const [countproject, setCountproject] = useState(0);
    const [countpartner, setCountpartner] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < 1000) {
                setCount((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 20);

        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countproject < 5) {
                setCountproject((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 150);

        return () => clearInterval(interval);
    }, [countproject]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countpartner < 4) {
                setCountpartner((prevCount) => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 150);

        return () => clearInterval(interval);
    }, [countpartner]);

    return (
        <section className="why-choose-us ">
            <div className=" why-choose-us-cent  container">
                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box">
                        <div className="content">
                            <div className="icon">
                            <i class="icofont-people"></i>
                            </div>
                            <div className="counter">
                                <h5>+ {count}</h5>
                            </div>

                            <h5>{strings.youthBen}</h5>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box">
                        <div className="content">
                            <div className="icon">
                            <i class="icofont-folder-open"></i>{" "}
                            </div>
                            <div className="counter">
                                <h5>+ {countproject} </h5>
                            </div>
                            <h5>{strings.projetsBen} </h5>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box">
                        <div className="content">
                            <div className="icon">
                            <i class="icofont-handshake-deal"></i>                            </div>
                            <div className="counter">
                                <h5>{countpartner} </h5>
                            </div>
                            <h5>{strings.partnersBen}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Features);

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const MainBanner = (props) => {
    strings.setLanguage(props.languageCode);
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = 21; // Réglez la position de lecture à 50 secondes
        videoRef.current.play(); // Démarrez la lecture
      }
    }, []);
    return (
        <div className="page-title-area actu item-bg2">
            <div className="container">
  
                <h1>{strings.Organisations_Members}</h1>
                <ul>
                    {/* <li><Link to="/">{strings.Accueil}</Link></li>
                    <li>{strings.ORGANISATIONS}</li> */}
                </ul>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(MainBanner);

import React, { useEffect, useRef, useState } from "react";
import lax from "lax.js";
import LaxButton from "../Shared/LaxButton";
import axios from "../../api/axios";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

const About = (props) => {
    const [posts, setPosts] = useState({});

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener("scroll", handleScroll, false);

        return () => {
            document.removeEventListener("scroll", handleScroll, false);
        };
    });

    useEffect(() => {
        axios.get(`get_about`).then((res) => {
            const aboutData = res.data.about[0];
            setPosts(aboutData);
        });
    }, []);
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = elementRef.current;
            if (element) {
                const elementTop = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                // Check if the element is in the viewport
                if (elementTop < windowHeight) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Initial check on component mount
        handleScroll();

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two ptb-120">
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div
                        ref={elementRef}
                        className={`col-lg-6  scroll-animation ${
                            isVisible ? "visibleText" : ""
                        }`}
                    >
                        <div className="about-content">
                            <h2>
                                <b>-</b>{" "}
                                {strings.hreflangCode === "fr"
                                    ? posts.presentation_title
                                    : strings.hreflangCode === "ar"
                                    ? posts.presentation_title_arabe
                                    : posts.presentation_title_eng}
                            </h2>
                            <p>
                                {strings.hreflangCode === "fr"
                                    ? posts.presentation
                                    : strings.hreflangCode === "ar"
                                    ? posts.presentation_arabe
                                    : posts.presentation_eng}
                            </p>
                        </div>
                        <div className="m-5">
                            <LaxButton />
                        </div>
                    </div>

                    <div
                        ref={elementRef}
                        className={`col-lg-6  scroll-animation ${
                            isVisible ? "visible" : ""
                        }`}
                    >
                        <div className="about-image">
                            <img
                                src={`https://dashboard.futurevisions.tn/${posts.photo_presentation}`}
                                className="about-img1"
                                alt="about"
                            />
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(About);

import React from 'react';
import MainBanner from '../OrganisationsList/MainBanner';
import OrganisationsList from '../OrganisationsList/OrganisationsList';
import Footer from '../Common/Footer';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
import { useEffect } from 'react';
const strings = new LocalizedStrings(localeFile);
function SpeakersThree (props) {

    useEffect(() => {
        strings.setLanguage(props.languageCode);
      }, [props.languageCode]);
    
        return (
            <React.Fragment>
                <MainBanner />
                <OrganisationsList />
            
            </React.Fragment>
        );
    
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(SpeakersThree);
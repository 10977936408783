import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const MainBanner = (props) => {
    strings.setLanguage(props.languageCode);
    const videoRef = useRef(null);

   
    return (
        <div className="page-title-area  about item-bg2">
            <div className="container">

                <h1> {strings.a_propos}</h1>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(MainBanner);


import React, { useState, useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import axios from '../../api/axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';
const strings = new LocalizedStrings(localeFile);

const MainBanner = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [posts, setPosts] = useState([]);

    const openModal = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        axios.get(`get_about`)
            .then(res => {
                const fetchedPosts = res.data.about[0];
                setPosts(fetchedPosts);
            });
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <React.Fragment>
            <ModalVideo
                channel='youtube'
                isOpen={isOpen}
                videoId={posts.video_presentation}
                onClose={() => setIsOpen(false)}
            />

            <div className="main-banner item-bg2 ">
                <div className="d-table">

                    <div className="d-table-cell">
                        <div className="">

                            <div className="main-banner-content banner-content-center">
                                <h1>
                                    <span>{strings.hreflangCode === 'fr' ? posts.big_title : strings.hreflangCode === 'ar' ? posts.big_title_arabe : posts.big_title_eng}</span>
                                </h1>

                                <div className="button-box">
                                    {/* <Link
                                        onClick={(e) => {
                                            e.preventDefault();
                                            openModal();
                                        }}
                                        to="#"
                                        className="video-btn popup-youtube"
                                    >
                                        <i className="icofont-ui-play"></i> <span>{strings.regarderz_video_promo}</span>
                                    </Link> */}
                                    <p className='pt-5' > {strings.slogan}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className="shape1"></div>
                <div className="shape2 rotateme"></div>
                <div className="shape3 rotateme"></div>
                <div className="shape4">
                    <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
                </div> */}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(MainBanner);



import React from 'react'
import MainBanner from '../schedule/MainBanner';
import EventSchedulesFour from '../schedule/EventSchedulesFour';
import Footer from '../Common/Footer';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';
import { useEffect } from 'react';
const strings = new LocalizedStrings(localeFile);

function ScheduleFour(props)  {
    
 useEffect(() => {
    strings.setLanguage(props.languageCode);
  }, [props.languageCode]);
        return (
            <React.Fragment>
                <MainBanner />
                <EventSchedulesFour />
             
            </React.Fragment>
        );
    
}
 const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(ScheduleFour);
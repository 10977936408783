import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import axios from "../../api/axios";

import { connect } from "react-redux";
import  logo  from "../../assets/images/LOGO FUTURE VISIONS 2023-25.jpg";
import NavBarfooter from "../Navigation/Navigationfoooter";

const Footer = (props) => {
    const strings = new LocalizedStrings(localeFile);
    const [social, setSocial] = useState({});

    useEffect(() => {
        axios.get(`/getSocial`).then((res) => {
            const socialData = res.data.social[0];
            setSocial(socialData);
        });
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <footer className="footer-area">
            <div className="copyright-area socialicons">
                <div className="container-fluid row">
                    <div className="col-md-4 px-4">
                        <h4>
                            <img src= { logo } height={70} width={200}/>
                            {/* {strings.hreflangCode == "fr"
                                ? social.Titre
                                : social.Titre_arabe}{" "} */}
                        </h4>
                        <p>
                            {strings.hreflangCode == "fr"
                                ? social.description
                                :strings.hreflangCode == "ar"? social.description_arabe:social.description_eng}
                        </p>
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            {social.Facebook != null ? (
                                <a className="social" href={social.Facebook}>
                                    <i className="icofont-facebook  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Youtube != "" ? (
                                <a className="social" href={social.Youtube}>
                                    {" "}
                                    <i className="icofont-youtube  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Linkedin != "" ? (
                                <a className="social" href={social.Linkedin}>
                                    {" "}
                                    <i className="icofont-linkedin  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}

                            {social.Twitter != "" ? (
                                <a className="social" href={social.Twitter}>
                                    {" "}
                                    <i className="icofont-twitter  custom-icon mx-2"></i>
                                </a>
                            ) : (
                                ""
                            )}
                            {social.Instagram != "" ? (
                                <a className="social" href={social.Instagram}>
                                    {" "}
                                    <i className="icofont-instagram  custom-icon mx-2"></i>{" "}
                                </a>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                   
                    <div className="col-md-8 p-0 map  col-lg-8  text-center">
                    <iframe  
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1618.8326142652647!2d10.599717710513962!3d35.7590322558612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fdf535b3aad113%3A0x2f3d487405989492!2zQXNzb2NpYXRpb24gRnV0dXJlIFZpc2lvbnMgLSDYrNmF2LnZitipINix2KTZiSDYp9mE2YXYs9iq2YLYqNmE!5e0!3m2!1sen!2stn!4v1697545410369!5m2!1sen!2stn" 
            
                     style={{border:"0"}}
                      allowfullscreen=""
                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        
                    </div>
                </div>
           
                <div className="mt-3 text-center">
                    <NavBarfooter/>
                    <p>
                        Copyright <i className="icofont-copyright"></i> 2023{" "}
                        {strings.FutureVisions}.{" "}
                        {strings.All_rights_reserved}
                    </p>
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Footer);

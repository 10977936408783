import React from 'react';
import * as actionCreators from '../store/actions/actionCreators';
import { connect } from 'react-redux';




const LanguageSwitch = ({ languageCode, langHandler }) => {
  const setPageDirection = (language) => {
    document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';

  };

  const handleChangeLanguage = (event) => {
    const newLanguageCode = event.target.value;
    setPageDirection(newLanguageCode);
    langHandler(newLanguageCode);
  };
  const flagImages = [
    { code: 'fr', image:   <img src={'../assets/images/fr-flag.png'}  />  },
    { code: 'ar', image:  <img src={'../assets/images/fr-flag.png'}  />  },
    { code: 'en', image:  <img src={'../assets/images/fr-flag.png'}  />  },
  ];;
  return (
    <div className="language-switch">
    <select className="lang-dropdown" value={languageCode} onChange={handleChangeLanguage}>
    {flagImages.map((flag) => (
          <option key={flag.code} value={flag.code} className={`lang-option ${flag.code}-flag`}>
          {flag.code.toUpperCase()}
          </option>
        ))}
    </select>
  </div>


  );
};

const mapStateToProps = (state) => {
  return {
    languageCode: state.languageCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    langHandler: (langCode) => dispatch(actionCreators.changeLanguage(langCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);

import React from 'react'
import imageSeif from '../../assets/images/teams/seif.png'
import imageRania from '../../assets/images/teams/rania.jpg'
import imageAmel from '../../assets/images/teams/amel.jpg'
import imageLatifa from '../../assets/images/teams/latifa.jpg'
import imageYassine from '../../assets/images/teams/yassine.jpg'
import imageLamiss from '../../assets/images/teams/lamiss.JPG'

import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';
import TeamCard from './TeamCard';

const strings = new LocalizedStrings(localeFile);

const Teams = (props) => {    
     strings.setLanguage(props.languageCode);

  return (
    <section className='container cabout  teams'>
    
    <div className="section-title">
        <h2><b>{ strings.Team }</b></h2>
        <div className="bar"></div>
    </div>
    <div className='container'>
    <TeamCard image={imageSeif} name={strings.seif} poste={strings.president} linkedin={'https://www.linkedin.com/in/seifallah-bensalem/'} email={'seifallahbs@futurevisions.tn'}/>
    <TeamCard image={imageYassine} name={strings.yassine} poste={strings.treasurer} linkedin={'https://www.linkedin.com/in/mohamed-yassine-ben-abdelkrim-11b562190/'} email={'yassineba@futurevisions.tn'}/>
    <TeamCard image={imageLamiss} name={strings.lamiss} poste={strings.secretary} linkedin={'https://www.linkedin.com/in/lamiss-khalfallah-97b05722b/'} email={'lamisskh@futurevisions.tn'}/>
    <TeamCard image={imageLatifa} name={strings.latifa} poste={strings.financeRes} linkedin={'https://www.linkedin.com/in/latifa-bargou-30ab511a3/'} email={'bargoulatifa@futurevisions.tn'}/>
    <TeamCard image={imageAmel} name={strings.amel} poste={strings.coord} linkedin={'https://www.linkedin.com/in/amel-lagha-2b1248134/'} email={'amellagha@futurevisions.tn'}/>
    <TeamCard image={imageRania} name={strings.rania} poste={strings.webdev} linkedin={'https://www.linkedin.com/in/seifallah-bensalem/'} email={'contact@ranialakoud.com'}/>

    </div>
    </section>
  )
}



const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(Teams);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';
import axios from '../../api/axios';
import ImageWithPlayIcon from '../Shared/ImageWithPlayIcon';
import ModalVideo from 'react-modal-video';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const options = {
    nav: false,
    dots: true,
    margin: 8,
    startPosition: 1,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        }
    }
}

const Partner = (props) => {
    const [posts, setPosts] = useState([]);
    const [dataVideo, setDataVideo] = useState([]);
    const [dataImage, setDataImage] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        axios.get(`get_about`)
            .then(res => {
                const aboutData = res.data.about[0];
                setPosts(aboutData);
                if (aboutData) {
                    setDataImage(aboutData.about_images);
                }
                setDataVideo(aboutData.about_videos);
            })
            .catch(({ response }) => {

            });
    }, []);

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener('scroll', handleScroll, false);

        return () => {
            document.removeEventListener('scroll', handleScroll, false);
        };
    }, []);

    const openModal = () => {
        setIsOpen(true);
    };

    strings.setLanguage(props.languageCode);

    return (
        <section className="partner-area ">
            <ModalVideo
                channel='youtube'
                autoplay
                isOpen={isOpen}
                videoId={url}
                onClose={() => setIsOpen(false)}
                loading={() => <div>Loading...</div>}
            />
            <div className="container">
                <div className="section-title">
                    <h2><b>{strings.Galerie}</b></h2>
                    <div className="bar"></div>
                </div>

                <div className="row">
                    {dataImage.length > 0 &&     <div className="col-lg-12">
                        <div className="partner-title platinum-sponsor">
                            <PlatinumSponsors />
                        </div>
                    </div>}
                

                    <OwlCarousel className="carsoul" {...options}>
                        {dataImage.length !== 0 && dataImage.map((imag, index) => (
                            <div className="col-lg-12 col-md-12" key={index}>
                                <div className="m-3">
                                    <img className="img" height={400} width={100} src={`https://dashboard.futurevisions.tn/${imag.image}`} alt="Partner Logo" />
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>

                    {dataVideo.length >0 && 
                    <>
                    <div className="col-lg-12">
                        <div className="border"></div>
                    </div>

                    <div className="col-lg-12">
                        <div className="partner-title gold-sponsor">
                            <GoldSponsors />
                        </div>
                    </div></>}

                    <div className='container-fluid'>
                        <OwlCarousel className="carsoul" {...options}>
                            {dataVideo.length !== 0 && dataVideo.map((video, index) => (
                                <div className="col-lg-12 col-md-12" key={index}>
                                    <div className="author-vd m-3">
                                        <Link to="#" className="img" onClick={() => { setIsOpen(true); setUrl(video.url); }}>
                                            <ImageWithPlayIcon src={`http://127.0.0.1:8000/${video.image}`} />
                                            <h4 className="widget-title text-center">
                                                {video.title}
                                            </h4>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = state => {
    return {
        languageCode: state.languageCode
    }
}

export default connect(mapStateToProps)(Partner);

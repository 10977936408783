import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import lax from 'lax.js';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const GoldSponsors = (props) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const element = ReactDOM.findDOMNode(elementRef.current);
        lax.addElement(element);

        return () => {
            lax.removeElement(element);
        };
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <h3 className="lax" data-lax-preset="driftLeft" ref={elementRef}>
            {strings.Galerie_Des_Videos}
        </h3>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(GoldSponsors);

import React, { useState } from 'react';
import   {AiOutlinePlayCircle} from 'react-icons/ai';

function ImageWithPlayIcon({ src }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      style={{ position: 'relative' }} 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      <img 
        src={src} 
        style={{ opacity: isHovered ? 0.7 : 1,height: '300px '}} 
      />
      {isHovered && 
        <div 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
            <AiOutlinePlayCircle  size={'50px'} color={'black'}/>
          <i className="fa fa-play-circle" style={{ fontSize: '40px', color: 'black' }} />
        </div>
      }
    </div>
  );
}
export default ImageWithPlayIcon
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import SimpleDateTime from "react-simple-timestamp-to-date";
import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

const strings = new LocalizedStrings(localeFile);

function BlogOne(props) {
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 30; // Réglez la position de lecture à 30 secondes
            videoRef.current.play(); // Démarrez la lecture
        }
    }, []);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const response = await axios.get(`get_categories`);
                const categories = response.data.category.flatMap((category) => category);
                setCategories(categories);
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        }

        fetchCategories();
    }, []);

    strings.setLanguage(props.languageCode);

    // Get all posts
    const allPosts = categories.flatMap(category => category.posts);

    // Calculate total pages
    const totalPages = Math.ceil(allPosts.length / itemsPerPage);

    // Get the posts for the current page
    const paginatedPosts = allPosts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <React.Fragment>
            <div className="page-title-area init item-bg2">
                <div className="container">
                    <h1>{strings.ACTUALITÉS}</h1>
                    <span>{strings.Nos_dernières_nouvelles}</span>
                </div>
            </div>

            <section className="blog-area ptb-120 bg-image">
                <div className="container">
                <div className="row">
                        {paginatedPosts.length > 0 &&
                            paginatedPosts.map((post, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="single-blog-post">
                                        <div className="blog-image">
                                            {post.post_images.length > 0 &&
                                                post.post_images.map((im, imIndex) => (
                                                    im.presentation == "1" && (
                                                        <img
                                                                                key={
                                                                                    index
                                                                                }
                                                                                src={
                                                                                    im.presentation ==
                                                                                        "1"
                                                                                        ? "https://dashboard.futurevisions.tn/" +
                                                                                        im.image
                                                                                        : ""
                                                                                }
                                                                            />
                                                    )
                                                ))}
                                            <div className="post-tag">
                                                <Link to="#">
                                                    {strings.hreflangCode === "fr"
                                                        ? categories.find(category =>
                                                            category.posts.some(p => p.id === post.id)
                                                        )?.name
                                                        : categories.find(category =>
                                                            category.posts.some(p => p.id === post.id)
                                                        )?.name_arabe}
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="blog-post-content">
                                            <span className="date">
                                                <SimpleDateTime
                                                    dateFormat="DMY"
                                                    dateSeparator="/"
                                                    timeSeparator=":"
                                                >
                                                    {post.updated_at}
                                                </SimpleDateTime>
                                            </span>
                                            <h3>
                                                <Link to={`/single-blog/id=${post.id}`}>
                                                    {strings.hreflangCode === "fr"
                                                        ? post.titre
                                                        : strings.hreflangCode === "ar"
                                                            ? post.titre_arabe
                                                            : post.titre_eng}
                                                </Link>
                                            </h3>
                                            <div style={{
                                               height: "10rem", overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                            }}>
                                                <p>
                                                    {strings.hreflangCode === "fr"
                                                        ? post.mini_description
                                                        : strings.hreflangCode === "ar"
                                                            ? post.mini_description_arabe
                                                            : post.mini_description_eng}
                                                </p>
                                            </div>
                                        
                                        </div>
                                        <div className="read-more-btn-container">
                                            <Link to={`/single-blog/id=${post.id}`} className="read-more-btn">
                                                {strings.En_savoir_plus}
                                                {strings.hreflangCode === "fr" ? (
                                                    <i className="icofont-double-right"></i>
                                                ) : (
                                                    <i className="icofont-double-left"></i>
                                                )}
                                            </Link>   </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <nav className="paginate" aria-label="Page navigation">
                        <ul className="pagination">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageClick(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <span aria-hidden="true">«</span>
                                </button>
                            </li>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li
                                    key={index + 1}
                                    className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageClick(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageClick(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    <span aria-hidden="true">»</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    languageCode: state.languageCode,
});

export default connect(mapStateToProps)(BlogOne);

import React, { useState, useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import axios from '../../api/axios';
import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";
import { Link } from 'react-router-dom';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const EventSchedulesFour = (props) => {
    const [resources, setResources] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');

    useEffect(() => {
        axios.get(`get_ressources`)
            .then(res => {
                const resources = res.data.resource;
                setResources(resources);
            });
    }, []);

    const totalPages = Math.ceil(resources.length / itemsPerPage);

    const paginatedResources = resources.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const openTabSection = (evt, tabName) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += "current";
    };

    const openModal = (url) => {
        setUrl(url);
        setIsOpen(true);
    };

    strings.setLanguage(props.languageCode);

    return (
        <section className="schedule-area schedule-style-four bg-image ptb-120">
            <ModalVideo
                channel='youtube'
                autoplay
                isOpen={isOpen}
                videoId={url}
                onClose={() => setIsOpen(false)}
                loading={() => <div>{strings.Loading}</div>}
            />
            <div className="container">
                <div className="section-title">
                    <div className="bar"></div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab">
                            <div className="row">
                                {strings.hreflangCode === 'ar' && (
                                    <div className="col-lg-4 col-md-3">
                                        <ul className="tabs active">
                                            <li onClick={(e) => openTabSection(e, 'tab1')} className="current">
                                                <Link to="#">
                                                    {strings.GUIDE}
                                                </Link>
                                            </li>
                                            <li onClick={(e) => openTabSection(e, 'tab2')}>
                                                <Link to="#">
                                                    {strings.list}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <div className="col-lg-8 col-md-9">
                                    <div className="tab_content">
                                        <div id="tab1" className="tabs_item">
                                            <ul className="accordion">
                                                {paginatedResources.length > 0 && (
                                                    paginatedResources.map((resource, index) => {
                                                        if (resource.urlpdf != null) {
                                                            return (
                                                                <li className="accordion-item" key={index}>
                                                                    <div className="accordion-title">
                                                                        <div className="author">
                                                                            <img
                                                                                src={`https://dashboard.futurevisions.tn/${resource.image}`}
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                alt="Img"
                                                                            />
                                                                        </div>
                                                                        <div className="schedule-info mx-3">
                                                                            <h3>{strings.hreflangCode === 'fr' ? resource.titre :strings.hreflangCode === 'ar' ?  resource.titre_arabe :resource.titre_eng}</h3>
                                                                            <ul>
                                                                                <li>
                                                                                    <a target="_blank" rel="noreferrer" href={`https://dashboard.futurevisions.tn/${resource.urlpdf}`} download>
                                                                                        <span>{strings.Télécharger}</span>
                                                                                        <i className="icofont-download"></i>
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                )}
                                            </ul>
                                        </div>
                                        <div id="tab2" className="tabs_item">
                                            <h2> {strings.list}</h2>

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th> {strings.valeurDinar} </th>
                                                        <th>{strings.funder}</th>
                                                        <th>{strings.year}</th>
                                                        <th>{strings.topic}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td dir='ltr'>10 000</td>
                                                        <td>منظمة أنا يقظ - I WATCH </td>
                                                        <td>2019</td>
                                                        <td>ملاحظة الانتخابات التشريعية والرئاسية لسنة 2019
                                                            - Observation des élections législatives et présidentielles de 2019
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td dir='ltr'>12 000</td>
                                                        <td>منظمة أنا يقظ - I WATCH </td>
                                                        <td>2020</td>
                                                        <td>خطة العمل المجتمعية سوسة
                                                            Plan d'action communautaire Sousse
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td dir='ltr'>18 000</td>
                                                        <td>منظمة أنا يقظ - I WATCH </td>
                                                        <td>2020</td>
                                                        <td>Soutenir le processus de transparence et d’accès à l’information au niveau local et renforcer la gouvernance participative.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td dir='ltr'>48 000</td>
                                                        <td>OXFAM</td>
                                                        <td>2022</td>
                                                        <td>Parcours démocratique - Implication des jeunes dans la vie publique
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td dir='ltr'>50 000</td>
                                                        <td>GIZ</td>
                                                        <td>2022</td>
                                                        <td>Améliorer l'état de la transparence en Tunisie et à promouvoir une culture de lutte contre la corruption parmi les jeunes
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div id="tab3" className="tabs_item">
                                            <ul className="accordion">
                                                {paginatedResources.length > 0 && (
                                                    paginatedResources.map((resource, index) => {
                                                        if (resource.video != null) {
                                                            return (
                                                                <li className="accordion-item" key={index}>
                                                                    <Link className="accordion-title" to="#">
                                                                        <div className="author-vd">
                                                                            <Link to="#" onClick={() => openModal(resource.video)}>
                                                                                <ImageWithPlayIcon src={`http://admin.futurevisions.tn/${resource.image}`} />
                                                                            </Link>
                                                                            <div className='icon-play-vd'>
                                                                                <AiOutlinePlayCircle size={'50px'} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="schedule-info mx-3">
                                                                            <h3>{strings.hreflangCode === 'fr' ? resource.titre :strings.hreflangCode === 'ar' ? resource.titre_arabe:resource.titre_eng}</h3>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {strings.hreflangCode === 'fr' && (
                                    <div className="col-lg-4 col-md-3">
                                        <ul className="tabs active">
                                            <li onClick={(e) => openTabSection(e, 'tab1')} className="current">
                                                <Link to="#">
                                                    {strings.GUIDE}
                                                </Link>
                                            </li>
                                            <li onClick={(e) => openTabSection(e, 'tab2')}>
                                                <Link to="#">
                                                    {strings.list}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {strings.hreflangCode === 'en' && (
                                    <div className="col-lg-4 col-md-3">
                                        <ul className="tabs active">
                                            <li onClick={(e) => openTabSection(e, 'tab1')} className="current">
                                                <Link to="#">
                                                    {strings.GUIDE}
                                                </Link>
                                            </li>
                                            <li onClick={(e) => openTabSection(e, 'tab2')}>
                                                <Link to="#">
                                                    {strings.list}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="paginate" aria-label="Page navigation">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={() => handlePageClick(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <span aria-hidden="true">«</span>
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li
                                key={index + 1}
                                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageClick(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={() => handlePageClick(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <span aria-hidden="true">»</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        languageCode: state.languageCode
    }
}

export default connect(mapStateToProps)(EventSchedulesFour);

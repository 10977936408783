import React, { useState } from "react";

function Modal({title,date, image ,description }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <span className="Modal">
<div className="plus-button">
      <span
        className="openModalBtn"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Lire la suite
      </span>

      </div>

      {modalOpen &&     
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
                setModalOpen(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
        <h4>{title}</h4>
        <div className="event-date">
                                        <span>{date}</span>
                                    </div>
            <img src= {image}/>
      
        </div>
        <div className="body">
          <p>{description}</p>
        </div>
     
      </div>
    </div>}
    </span>
  );
}

export default Modal;
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);

class LaxButton extends React.Component {


    render(){        
        strings.setLanguage(this.props.languageCode);

        return (
            <Link to="/about-2" className="btn btn-primary " >
           {strings.En_savoir_plus}
            </Link>
        );
    }
}

LaxButton.defaultProps = {
    buttonText: 'Explore More About'
};
 



const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(LaxButton);
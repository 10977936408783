import React, { useState, useEffect } from 'react';
import AppRouter from './Routes';
import Preloader from './components/Shared/Preloader';
import LocalizedStrings from 'react-localization';
import localeFile from './local/content';
import { connect } from 'react-redux';
import '../node_modules/react-modal-video/css/modal-video.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/animate.min.css';
import './assets/css/style.css';

const strings = new LocalizedStrings(localeFile);

const App = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        demoAsyncCall().then(() => setLoading(false));
    }, []);

    const demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    };

    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleButtonClick = () => {
        setIsButtonClicked(!isButtonClicked);
    };

    const language = props.languageCode;
    let dir;

    if (language === 'fr') {
        dir = 'ltr';
    } else if (language === 'ar') {
        dir = 'rtl';
    } else {
        dir = 'ltr';
    }

    document.documentElement.dir = dir;

    return (
        <React.Fragment>
            {loading ? <Preloader /> : ''}
            <AppRouter />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(App);

import { Link } from 'react-router-dom';
import Footer from '../Common/Footer';
import axios from '../../api/axios';
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import { useParams } from 'react-router-dom';
import React, {useState,useEffect}  from "react"

import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
    function BlogSpecCategory(props)  {strings.setLanguage(props.languageCode);
        const [data, setData] = useState('');
        const [categories, setcategories] = useState([]);
        const [posts, setposts] = useState([]);
        const [imagespost, setimagespost] = useState([]);
        const { id } = useParams()
        const [dateObject, setDateObject] = useState(null);
        // const [t,i18n] = useTranslation();
    
        useEffect(() => {
            async function getPost() {
               //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
                await axios.get(`https://dashboard.futurevisions.tn/api/${id}`)
                  .then(res => {
                    const post = res.data.category;
            
                    setData(post);
                    setposts(post.posts)
                    // setimagespost(post.post_images);
         
                  })
    
            } getPost() }, []); 

        return (
            <React.Fragment>
                <div className="page-title-area plus  item-bg2">
                    <div className="container">
                        <h1>{strings.ACTUALITÉS}</h1>
                        <span>{strings.Nos_dernières_nouvelles}</span>
                        <ul>
                            {/* <li><Link to="/">Home</Link></li>
                            <li>Blog</li> */}
                        </ul>
                    </div>
                </div> 

                <section className="blog-area ptb-120 bg-image">
                    <div className="container">
                        <div className="row">
                        
                        { posts.length != 0 && (posts.map((post,index) => {
         
         return     <div className="col-lg-4 col-md-6">
         <div className="single-blog-post">
             <div className="blog-image">
   

          {  post.post_images.length > 0 && ( post.post_images.map((im,index) => {
         return <img src={im.presentation=='1'?"https://dashboard.futurevisions.tn/"+im.image :""}  />}))}
                 <div className="post-tag">
                     <Link to="#">{strings.hreflangCode=='fr'?  data.name :strings.hreflangCode=='ar'?  data.name_arabe:data.name_eng}</Link>
                 </div>
             </div>

             <div className="blog-post-content">
                 <span className="date"><SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{post.updated_at}</SimpleDateTime></span>
                 <h3>                      
                      <Link to={`/single-blog/id=${post.id}`}>{strings.hreflangCode=='fr'? post.titre :strings.hreflangCode=='ar'? post.titre_arabe : post.titre_eng }</Link>
</h3>
                 <p>{ strings.hreflangCode=='fr'? post.small_description : post.small_description_arabe}</p>
                 <Link  to={`/single-blog/id=${post.id}`}className="read-more-btn">{strings.En_savoir_plus}{strings.hreflangCode!='ar'?  <i className="icofont-double-right"> </i>: <i className="icofont-double-left"> </i>}</Link>
             </div>
         </div>
     </div>         
     }))} 

                        </div>
                    </div>
                </section>
     
            </React.Fragment>
        );
    
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(BlogSpecCategory);
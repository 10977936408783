import React from "react";

import LocalizedStrings from "react-localization";
import localeFile from "../../local/content";
import { connect } from "react-redux";

import { useRef } from "react";
import { useEffect } from "react";

const strings = new LocalizedStrings(localeFile);

const Values = (props) => {
    strings.setLanguage(props.languageCode);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 12; // Réglez la position de lecture à 50 secondes
            videoRef.current.play(); // Démarrez la lecture
        }
    }, []);
    return (
    <div className="container cabout ptb-70">
        <div className="section-title">
                <h2>
                    <b>{strings.values}</b>
                </h2>
                <div className="bar"></div>
            </div>
        <section className="why-choose-us-values values">


            <video autoPlay loop muted className="slideshow" ref={videoRef}>
                <source src="https://d2t3k1w51fob7.cloudfront.net/S%C3%A9quence%2003.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className=" why-choose-us-cent-values  container">
                <div className="col-lg-3 col-sm-6  p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i class="icofont-award"></i>{" "}
                            </div>
                            <h3>{strings.Excellence}</h3>
                            <p>{strings.ExcellenceT}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i class="icofont-brainstorming"></i>
                            </div>
                            <h3>{strings.Innovation}</h3>
                            <p>{strings.InnovationT}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i class="icofont-users-alt-5"></i>
                            </div>
                            <h3>{strings.Inclusivity}</h3>
                            <p>{strings.InclusivityT}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i class="icofont-shield-alt"></i>{" "}
                            </div>
                            <h3>{strings.Integrity}</h3>
                            <p>{strings.IntegrityT}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i className="icofont-network-tower"></i>
                            </div>
                            <h3>{strings.SocialImpact} </h3>
                            <p>{strings.SocialImpactT}</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-sm-6 p-0">
                    <div className="single-box-values">
                        <div className="content-values">
                            <div className="icon-values">
                                <i class="icofont-slidshare"></i>
                            </div>
                            <h3>{strings.ByYouth}</h3>
                            <p>{strings.ByYouthT}</p>
                        </div>
                    </div>
                </div>
            </div>

           
           
        </section></div>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode,
    };
};

export default connect(mapStateToProps)(Values);

import React, { useState, useEffect } from 'react';
import lax from 'lax.js';
import axios from '../../api/axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { connect } from 'react-redux';

const strings = new LocalizedStrings(localeFile);

const About = (props) => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener('scroll', handleScroll, false);

        axios.get('/get_about')
            .then((res) => {
                const aboutData = res.data.about[0];
                setPosts(aboutData);
            });

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    strings.setLanguage(props.languageCode);

    return (
        <section className="about-area-two ptb-120 bg-image">
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content">
                            <h2><b>-</b> {strings.hreflangCode === 'fr' ? posts.presentation_title :strings.hreflangCode === 'ar' ? posts.presentation_title_arabe :posts.presentation_title_eng}</h2>
                            <p>{strings.hreflangCode === 'fr' ? posts.presentation :strings.hreflangCode === 'ar' ? posts.presentation_arabe:posts.presentation_eng}</p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-image">
                            <img src={"https://dashboard.futurevisions.tn/" + posts.photo_presentation} className="about-img1" alt="about" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        languageCode: state.languageCode
    };
};

export default connect(mapStateToProps)(About);

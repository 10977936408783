import React from 'react';
import MainBanner from '../AboutTwo/MainBanner';
import About from '../AboutTwo/About';
import Gallery from '../Common/Gallery';
import GoTop from '../Shared/GoTop';
import FeaturesAbout from '../AboutTwo/FeaturesAbout';
import Values from '../AboutTwo/Values';
import Teams from '../AboutTwo/Teams';

const AboutTwo = () => {
    return (
        <React.Fragment>
            <MainBanner />
            <About />
            <FeaturesAbout/>  
            <Values /> 
            <Teams/> 
            <Gallery />
            
            <GoTop scrollStepInPx="50" delayInMs="16.66" />
        </React.Fragment>
    );
};

export default AboutTwo;

import React, { useState, useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';

const strings = new LocalizedStrings(localeFile);

const NavBarfooter = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                // elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                // elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }, []);

    const toggleOpen = () => setIsOpen(!isOpen);

    const onRouteChanged = () => {
        setIsOpen(false);
    }

    strings.setLanguage(props.languageCode);
    const classOne = collapsed ? 'collapse navbar-collapse ' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

    return (
        <>
            <div className='navfooter' >
                <div  className="">
                    <nav className="navbar navbar-expand-md  ">
                        <div className=" ">
                           
                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <label for="navbar-toggle" class="toggle-button">
                                    <span class="bar"></span>
                                    <span class="bar"></span>
                                    <span class="bar"></span>
                                </label>
                            </button>
                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav  ">
                                    <li className="nav-item">
                                        <NavLink
                                            to="/home"
                                            className="nav-link">
                                            {strings.Accueil}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/about-2"
                                            className="nav-link"
                                        >
                                            {strings.a_propos}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/organisations"
                                            className="nav-link"
                                        >
                                            {strings.ORGANISATIONS}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/blog-1"
                                            className="nav-link"
                                        >
                                            {strings.ACTUALITÉS}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/Ressources"
                                            className="nav-link"
                                        >
                                            {strings.RESSOURCES}
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink
                                            to="/contact"
                                            className="nav-link"
                                        >
                                            {strings.Contact}
                                        </NavLink>
                                    </li>
                                </ul>
                                <ul>
                                    <li className='mr-5' >
                                        <LanguageSwitch urlSuffix={['', '']} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        languageCode: state.languageCode
    }
}

export default connect(mapStateToProps)(NavBarfooter);
